
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import utilMixins from "@/mixins";
  import before_login from "@/vuex/before_login";
  import session from "@/vuex/session";
  import dialog from "@/vuex/dialog";
  import header from "@/vuex/header";
  import node from "@/vuex/node";
  import login from "@/vuex/login";
  import * as env from "@/env.js";
  import CommonIcon from "@/components/common/Icon.vue";
  import HeaderPassreset from "@/components/header/Passreset.vue";

  @Component({
    components: {
      CommonIcon,
      HeaderPassreset,
    },
  })
  export default class Login extends Mixins(utilMixins) {
    pass = "";
    mail = "";
    disp_app_popup = true;

    hide_app_popup() {
      this.disp_app_popup = false;
    }
    launchApp() {
      if (
        navigator.userAgent.indexOf("iPhone") > 0 ||
        navigator.userAgent.indexOf("iPad") > 0 ||
        navigator.userAgent.indexOf("iPod") > 0
      ) {
        document.location = "arrowpersonalapp://";
        const time = new Date().getTime();
        setTimeout(function () {
          const now = new Date().getTime();

          if (now - time < 400) {
            document.location =
              "https://apps.apple.com/us/app/arrow%E3%83%9E%E3%82%A4%E3%83%9A%E3%83%BC%E3%82%B8/id1605511177";
          }
        }, 300);
      } else if (navigator.userAgent.indexOf("Android") > 0) {
        document.location =
          "intent://profile/341219895930508#Intent;scheme=fb;package=com.facebook.katana;end";
      }
    }
    openPassReset() {
      header.setIsOpenPassDialog(true);
    }

    async created() {
      if (this.query.mail !== undefined) {
        this.mail = this.query.mail;
      }
      await before_login.fetch(this.company_code);
      if (this.before_login?.wizard == 0) {
        dialog.openErrorAlertDialog({
          title: "お知らせ",
          msg: "このページをブックマークしておくことをお勧めします。",
          detail: "次回ログインする際にブックマークからログインページを開けるようになります。",
        });
      }
      if (this.browser == "msie") {
        console.log("ブラウザはInternet Explorerです");
      } else if (this.browser == "trident") {
        console.log("ブラウザは古いEdgeです");
      } else if (this.browser == "edge") {
        console.log("ブラウザは旧Edgeです");
      } else if (this.browser == "edg") {
        console.log("ブラウザは新Edgeです");
      } else if (this.browser == "chrome") {
        console.log("ブラウザはChromeです");
      } else if (this.browser == "safari") {
        console.log("ブラウザはSafariです");
      } else if (this.browser == "firefox") {
        console.log("ブラウザはFireFoxです");
      }
    }

    get is_login() {
      return (
        util.getLocalStorage("company_id") &&
        util.getLocalStorage("branch_id") &&
        util.getLocalStorage("employee_id") &&
        util.getLocalStorage("auth")
      );
    }

    mounted() {
      this.$store.commit("storeObject", { dest: "loading_flag", object: 0 });
      this.$store.commit("deleteLoadingNodeDialog");
    }

    // get auth() {
    //   if (this.query.auth === undefined) {
    //     return 0;
    //   } else {
    //     if (isFinite(this.query.auth) && this.query.auth >= 0 && this.query.auth <= 3) {
    //       return this.query.auth;
    //     } else {
    //       this.query = { auth: 0 };
    //       return 0;
    //     }
    //   }
    // }
    // set auth(auth: number) {
    //   if (auth != 0) {
    //     util.changeLanguage("ja");
    //     console.log("aaa");
    //   }
    //   this.query = { auth };
    // }

    get company_name() {
      return this.before_login?.company_name ?? "";
    }
    get company_code() {
      return this.$route.params.company_code;
    }
    get branch_code() {
      if (this.query.branch_code === undefined) {
        return "";
      } else {
        return this.query.branch_code;
      }
    }
    set branch_code(branch_code: string) {
      this.query = { branch_code };
    }
    get is_maintenance() {
      return this.before_login?.is_maintenance ?? false;
    }
    get traial_remain_days() {
      return this.before_login?.traial_remain_days ?? null;
    }
    get use_status() {
      return this.before_login?.use_status ?? 1;
    }

    get before_login() {
      if (before_login.data) return before_login.data;
      else before_login.fetch(this.company_code);
    }

    get front_version() {
      return env.front_version;
    }
    get api_version() {
      return this.before_login?.api_version;
    }

    async login() {
      try {
        await session.login({
          company_code: this.company_code,
          mail: this.mail,
          pass: this.pass,
        });
      } catch (e) {
        if (e.type == "validation_error") dialog.openErrorDialog({ error: e.error });
        else if (e.type == "login_error")
          dialog.openErrorAlertDialog({ msg: util.$t("login_error") as string });
        else if (e.type == "login_no_admit_error")
          dialog.openErrorAlertDialog({ msg: util.$t("login_no_admit_error") as string });
        else if (e.type == "branch_error")
          dialog.openErrorAlertDialog({ msg: util.$t("branch_error") as string });
      }
    }

    async reset_demo() {
      await session.reset_demo();
    }
  }
